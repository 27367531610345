<template>
  <div>
    <v-data-table
      :headers="columns"
      :items="tableData"
      :items-per-page="10"
      :footer-props="{
        'items-per-page-options': [10, 50, 100]
      }"
      :options.sync="options"
      class="elevation-1"
    >
      <template v-for="(col, i) in filters" v-slot:[`header.${i}`]="{ header }">
        <div style="display: inline-block; padding: 16px 0;" v-bind:key="i">
          <span>{{ header.text }}</span>
        </div>
        <div style="float: right; margin-top: 8px" v-bind:key="`A` + i">
          <v-menu
            :close-on-content-click="false"
            :nudge-width="200"
            offset-y
            transition="slide-y-transition"
            left
            fixed
            style="position: absolute; right: 0"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="indigo" icon v-bind="attrs" v-on="on">
                <v-icon
                  small
                  :color="
                    activeFilters[header.value] &&
                    activeFilters[header.value].length <
                      filters[header.value].length
                      ? 'red'
                      : 'default'
                  "
                >
                  mdi-filter-variant
                </v-icon>
              </v-btn>
            </template>
            <v-list flat dense class="pa-0">
              <v-list-item-group
                multiple
                v-model="activeFilters[header.value]"
                class="py-2"
              >
                <div class="search_input">
                  <v-text-field
                    hide-details
                    v-model="searchString[header.value]"
                    placeholder="Search ..."
                    class="pl-2 pr-2"
                    @input="searchItem(header.value)"
                  ></v-text-field>
                </div>
                <template v-for="(item, j) in filters[header.value]">
                  <v-list-item
                    v-bind:key="`A${j}`"
                    :value="item"
                    :ripple="false"
                  >
                    <template v-slot:default="{ active, toggle }">
                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          :true-value="item"
                          @click="toggle"
                          color="primary"
                          :ripple="false"
                          dense
                        ></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title v-text="item"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
              <v-row no-gutters>
                <v-col cols="6">
                  <v-btn
                    text
                    block
                    @click="toggleAll(header.value)"
                    color="success"
                    >{{ $t("toggleall") }}</v-btn
                  >
                </v-col>
                <v-col cols="6">
                  <v-btn
                    text
                    block
                    @click="clearAll(header.value)"
                    color="warning"
                    >{{ $t("clearall") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <v-autocomplete
          v-model="editedItem.status"
          :items="user_status"
          filled
          :hide-details="true"
          dense
          v-if="item.id === editedItem.id"
        ></v-autocomplete>
        <span v-else>{{ item.status }}</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <div v-if="item.id === editedItem.id">
          <v-icon color="red" class="mr-3" @click="close">
            mdi-window-close
          </v-icon>
          <v-icon color="green" @click="save">
            mdi-content-save
          </v-icon>
        </div>
        <div v-else>
          <v-icon color="green" class="mr-3" @click="editItem(item)">
            mdi-pencil
          </v-icon>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import _ from "lodash";

export default {
  name: "veTableSubMaterials",
  props: ["row", "assortmentDetail", "update_table"],
  data: function() {
    return {
      editedIndex: -1,
      editedItem: {
        id: 0,
        name: 0,
        status: "active"
      },
      defaultItem: {
        id: 0,
        name: 0,
        status: "active"
      },
      current_rows: null,
      tableData: [],
      tempdata: [],
      options: {},
      loading: false,
      filterCriteria: {},
      user_status: ["active", "inactive"],
      activeFilters: {},
      filters: {
        name: [],
        status: []
      },
      initFilterList: {
        name: [],
        status: []
      },
      searchString: {
        name: "",
        status: ""
      }
    };
  },
  watch: {
    async row(newVal, oldVal) {
      if (newVal) {
        await this.init(newVal);
      }
    },
    options: {
      handler() {
        this.sortChange();
      },
      deep: true
    }
  },
  computed: {
    columns() {
      return [
        {
          text: "ID",
          value: "id"
        },
        {
          text: this.$t("Material") + " " + this.$t("brands.name"),
          value: "name",
          filter: value => {
            return this.activeFilters.name
              ? this.activeFilters.name.includes(value)
              : true;
          }
        },
        {
          text: this.$t("salesforce.status"),
          value: "status",
          width: "150px",
          filter: value => {
            return this.activeFilters.status
              ? this.activeFilters.status.includes(value)
              : true;
          }
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "100px"
        }
      ];
    }
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.tableData.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },
    close() {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },
    async save() {
      if (this.editedIndex > -1) {
        let postData = {
          assortmentId: this.editedItem.assortment_material.assortmentId,
          materialId: this.editedItem.assortment_material.materialId,
          status: this.editedItem.status
        };
        try {
          await ApiService.post(
            `assortment/update_assortment_materials`,
            postData
          );
          logInfo("Éxito !");
          Object.assign(this.tableData[this.editedIndex], this.editedItem);
          await this.update_table();
          this.close();
        } catch (error) {
          logError("Error occured !");
        }
      }
    },
    initFilters() {
      let keys = Object.keys(this.initFilterList);
      for (const key of keys) {
        this.searchString[key] = "";
        this.initFilterList[key] = this.tableData
          .map(d => {
            return d[key];
          })
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
      }
      this.filters = Object.assign({}, this.initFilterList);
      this.activeFilters = Object.assign({}, this.filters);
    },
    searchItem(col) {
      this.changeStr(col, this.searchString[col]);
    },
    changeStr(col, search_str) {
      if (search_str) {
        this.filters[col] = this.initFilterList[col].filter(item =>
          item.toLowerCase().includes(search_str.toLowerCase())
        );
      } else {
        this.filters[col] = this.initFilterList[col];
      }
      this.activeFilters = Object.assign({}, this.filters);
    },
    toggleAll(col) {
      this.activeFilters[col] = this.tableData
        .map(d => {
          return d[col];
        })
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
      this.searchString[col] = null;
      this.changeStr(col, null);
    },
    clearAll(col) {
      this.activeFilters[col] = [];
    },
    sortChange() {
      let { sortBy, sortDesc } = this.options;
      if (sortBy && sortBy.length > 0) {
        sortBy = sortBy[0];
        sortDesc = sortDesc[0];
        if (sortDesc) {
          this.tempdata = _.sortBy(this.tempdata, sortBy).reverse();
        } else {
          this.tempdata = _.sortBy(this.tempdata, sortBy);
        }
        this.tableData = this.tempdata;
      }
    },
    async init(newVal) {
      this.current_rows = newVal;
      this.current_rows = this.current_rows.map(item => {
        let push_item = { ...item };
        push_item["status"] = push_item?.assortment_material.status;
        return push_item;
      });
      this.tableData = this.current_rows;
      this.tempdata = this.current_rows;
      this.initFilters();
    }
  },
  async updated() {},
  async mounted() {
    this.init(this.row);
  }
};
</script>

<style>
.v-list--dense .v-list-item,
.v-list-item--dense {
  min-height: 20px !important;
  height: 2rem;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 0.5rem !important;
}

.v-list-item--link {
  transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.v-list-item--link:hover {
  background-color: rgba(0, 0, 0, 0.13);
}
</style>
